<template>
  <div class="space-y-5">
    <div class="bg-white shadow overflow-hidden rounded-lg" v-for="(section, sectionIndex) in sections" :key="sectionIndex">
      <div class="p-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900">{{ section.title }}</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">{{ section.description }}</p>
      </div>
      <div class="border-t border-gray-200">
        <dl class="">
          <div v-for="(question, questionIndex) in visibleQuestionsInSection(section)" :key="questionIndex" :class="[
            'p-4',
            { 'bg-gray-50': questionIndex % 2 === 0 }
          ]">
            <div class="lg:grid lg:grid-cols-3 lg:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                {{ question.title }}
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 lg:mt-0 lg:col-span-2">
                <input
                  :name="question.id"
                  class="hidden"
                  :type="question.type.name"
                  :value="question.value"
                  :required="question.required"
                />
                <template v-if="question.value !== undefined && question.value !== null">
                  <div class="flex-grow" :set="value = parseValueByQuestionType(question)">
                    <ul class="flex-grow" v-if="question.type.name === 'checkbox'">
                      <li v-for="(valueOption, valueOptionIndex) in value" :key="valueOptionIndex">
                        - {{ valueOption }}
                      </li>
                    </ul>
                    <span class="flex-grow" v-else-if="question.type.name === 'upload' && value !== undefined">
                      <a class="text-secondary hover:underline focus:underline" :href="`${accountStorageURI}/forms/submissions/${submissionData.id}/${value.name}`" target="_blank" v-show="value !== undefined">
                        {{ value.name }}
                        <i class="fas fa-file-download icon-replacement-fix-relative ml-1"/>
                      </a>
                    </span>
                    <span class="flex-grow" v-else>
                      {{ value }}
                    </span>
                  </div>
                </template>
                <span class="flex-grow text-gray-400" v-else>
                  Geen antwoord
                </span>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Public.Forms.Single.View',

  inject: ['axios'],

  props: {
    formId: Number,
    formTitle: String,
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    sectionData: {
      type: Object,
      default() {
        return {}
      }
    },
    submissionData: {
      type: Object,
      default() {
        return {}
      }
    },
    hasPreviousBtn: {
      type: Boolean,
      default: false
    },
    hasNextBtn: {
      type: Boolean,
      default: false
    },
    hasFinishBtn: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      accountStorageURI: process.env.VUE_APP_MS_BLOB_STORAGE_URL
    }
  },

  computed: {
    sections() {
      return this.formData.sections ?? []
    },

    hasSubmission() {
      return !!Object.keys(this.submissionData).length
    },

    submissionId() {
      return this.submissionData.id
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.setRouteMeta(this.sectionData.title, this.formTitle)
    },

    visibleQuestionsInSection(section) {
      return section.questions.filter((question) => !!question.visible)
    },

    parseValueByQuestionType(question) {
      if (question.value === undefined) {
        return question.value
      }

      let parsedValue = question.value
      if (['bool', 'select', 'radio'].includes(question.type.name)) {
        const relevantOption = question.options.filter((option) => option.option_value == question.value)
        if (relevantOption.length) {
          parsedValue = relevantOption[0].option_name
        }
      }
      if (question.type.name === 'checkbox') {
        let combinedNewValue = []
        const explodedValue = question.value.split(',')
        for (let i = 0; i < explodedValue.length; i++) {
          const value = explodedValue[i]
          const relevantOption = question.options.filter((option) => option.option_value == value)
          if (relevantOption.length) {
            combinedNewValue.push(relevantOption[0].option_name)
          }
        }
        parsedValue = combinedNewValue
      }

      return parsedValue
    },

    /**
     * Set's the meta of the current route and emits an event to rerender breadcrumb
     *
     * @param {String} title
     * @return {Promise}
     */
    async setRouteMeta(title, formTitle) {
      let formRoute = this.$route.matched.filter((route) => route.name === 'Public.Forms.Single')
      let sectionRoute = this.$route.matched.filter((route) => route.name === 'Public.Forms.Single.Section')
      if (sectionRoute.length) {
        sectionRoute[0].meta.breadcrumbTitle = title
      }
      if (formRoute.length) {
        formRoute[0].meta.breadcrumbTitle = formTitle
      }
      return Promise.resolve()
    },
  },

  beforeRouteUpdate() {
    this.setRouteMeta(this.sectionData.title, this.formTitle)
  }
}
</script>
