<template>
  <div class="shadow rounded-md overflow-hidden">
    <div class="bg-white space-y-6">
      <div class="border-b p-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900" v-if="sectionData.title && sectionData.title.length">
          {{ sectionData.title }}
        </h3>
        <p class="mt-1 text-sm text-gray-500" v-if="sectionData.description && sectionData.description.length">
          {{ sectionData.description }}
        </p>
      </div>

      <div class="grid grid-cols-3 gap-5 px-4 pb-6">
        <div class="col-span-3" v-for="question in questions" :key="question.id" v-show="question.visible">
          <Field
            :label="question.title"
            :description="question.description"
            :required="!!question.required"
            :errors="question.errors ? question.errors : []">
            <component :is="getFieldComponentByType(question.type.name)"
              :required="!!question.required && question.visible ? true : false"
              :id="question.id"
              :name="question.id"
              :type="question.type"
              v-model:value="question.value"
              :submissionData="hasSubmission ? submissionData : {}"
              :submissionId="hasSubmission ? submissionId : null"
              :options="question.options"
              :dynamic_options="question.dynamic_options"
              :min="getRestrictionValueByTypeName('min', question.restrictions)"
              :max="getRestrictionValueByTypeName('max', question.restrictions)"
              :step="getRestrictionValueByTypeName('step', question.restrictions)"
              :maxLength="getRestrictionValueByTypeName('max_length', question.restrictions)"
              :placeholder="question.placeholder"
              :default="question.default_value"
              :questions="question.questions"
              :hasError="!!(question.errors && question.errors.length)"
              v-model:errors="question.errors"
              :formId="formId"
              autocomplete="off"
              @update:submissionData="(submissionData) => $emit('update:submissionData', submissionData)"
              @change="(val) => {
                question = inputChanged(question, val)
                this.$emit('update:submissionData', submissionData)
              }"
            />
          </Field>
        </div>
      </div>
    </div>
    <!-- <div :class="[
      'px-4 py-3 bg-gray-50 border-t flex flex-wrap',
      { 'justify-end': (hasNextBtn || hasFinishBtn) && !hasPreviousBtn },
      { 'justify-between': (hasNextBtn && hasPreviousBtn) || (hasPreviousBtn && !hasNextBtn) },
    ]" v-if="hasPreviousBtn || hasNextBtn || hasFinishBtn">
      <div class="w-1/2 lg:w-auto pr-2 lg:pr-0">
        <button
          type="button"
          :class="['btn-default h-12', { 'opacity-60 pointer-events-none': waitForSaving }]"
          @click="previousSection"
          :disabled="waitForSaving"
          v-if="hasPreviousBtn">
          <img :src="publicPath + '/img/spinner-gray.svg'" width="24" v-show="waitForSaving"/>
          <span v-show="!waitForSaving">Vorige</span>
        </button>
      </div>
      <div class="flex flex-wrap-reverse lg:flex-wrap lg:space-y-0 justify-end items-center w-1/2 lg:w-auto pl-2 lg:pl-0">
        <LastSavedTimer class="hidden lg:block" v-model:lastSaved="lastSaved"/>
        <button
          type="button"
          :class="['btn-default w-full lg:w-auto h-12', { 'opacity-60 pointer-events-none': waitForSaving }]"
          @click="nextSection"
          :disabled="waitForSaving"
          v-if="hasNextBtn">
          <img :src="publicPath + '/img/spinner-gray.svg'" width="24" v-show="waitForSaving"/>
          <span v-show="!waitForSaving">Volgende</span>
        </button>
        <button
          type="button"
          :class="['btn-default w-full lg:w-auto h-12', { 'opacity-60 pointer-events-none': waitForSaving }]"
          @click="finalize"
          :disabled="waitForSaving"
          v-if="hasFinishBtn">
          <img :src="publicPath + '/img/spinner-gray.svg'" width="24" v-show="waitForSaving"/>
          <span v-show="!waitForSaving">Naar controleren</span>
        </button>
      </div>
      <LastSavedTimer class="lg:hidden w-full" v-model:lastSaved="lastSaved"/>
    </div> -->
  </div>
</template>

<script>
import moment from 'moment'
import LastSavedTimer from '@/components/forms/LastSavedTimer'
import Field from '@/components/forms/Field'
import InputText from '@/components/forms/fields/InputText'
import InputNumber from '@/components/forms/fields/InputNumber'
import InputBoolean from '@/components/forms/fields/Boolean'
import InputCheckbox from '@/components/forms/fields/Checkbox'
import InputRadio from '@/components/forms/fields/Radio'
import InputDateTime from '@/components/forms/fields/InputDateTime'
import InputDate from '@/components/forms/fields/InputDate'
import InputTime from '@/components/forms/fields/InputTime'
import InputEmail from '@/components/forms/fields/InputEmail'
import InputFile from '@/components/forms/fields/InputFile'
import InputTextarea from '@/components/forms/fields/Textarea'
import InputSelect from '@/components/forms/fields/Select'
import InputTel from '@/components/forms/fields/InputTel'
import InputUrl from '@/components/forms/fields/InputUrl'
import Repeater from '@/components/forms/fields/Repeater'
import QR from '@/components/forms/fields/QR'
import Location from '@/components/forms/fields/Location'

export default {
  name: 'Public.Forms.Single.Section',

  inject: ['axios'],

  emits: [
    'update:routes',
    'update:submissionData',
    'update:sectionData',
    'update:waitForSaving',
    'inputChanged',
    'previous',
    'next',
    'finalize',
    'submit',
  ],

  components: {
    LastSavedTimer,
    Field,
    InputText,
    InputNumber,
    InputBoolean,
    InputCheckbox,
    InputRadio,
    InputDateTime,
    InputDate,
    InputTime,
    InputEmail,
    InputFile,
    InputTextarea,
    InputSelect,
    InputTel,
    InputUrl,
    Repeater,
    QR,
    Location
  },

  props: {
    formId: Number,
    formTitle: String,
    sectionData: {
      type: Object,
      default() {
        return {}
      }
    },
    submissionData: {
      type: Object,
      default() {
        return {}
      }
    },
    hasPreviousBtn: {
      type: Boolean,
      default: false
    },
    hasNextBtn: {
      type: Boolean,
      default: false
    },
    hasFinishBtn: {
      type: Boolean,
      default: false
    },
    waitForSaving: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      lastSaved: null,
      answersFilled: false,
      publicPath: window.location.origin
    }
  },

  computed: {
    questions() {
      return this.sectionData.questions ?? []
    },

    hasSubmission() {
      return !!Object.keys(this.submissionData).length
    },

    submissionId() {
      return this.submissionData.id
    },

    isProjectForm() {
      return this.$route.query.project && !isNaN(this.$route.query.project) ? true : false
    },

    projectId() {
      return this.isProjectForm ? parseInt(this.$route.query.project) : null
    },

    isFinalized() {
      return this.submissionData.finished && !this.$store.getters['Public/user/role/can']('survey.submission:edit')
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.setRouteMeta(this.sectionData.title, this.formTitle)
    },

    /**
     * Stores a new form submission
     *
     * @param {String} endpointURI
     * @param {Object} customBody
     * @return {Promise}
     */
    async storeSubmission(endpointURI, customBody = {}) {
      let body = Object.assign({
        form_id: this.formId
      }, customBody)

      if (this.isProjectForm) {
        body.project_id = this.projectId
      }

      try {
        var request = await this.axios.post(endpointURI, body)
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'Public.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'Public.Unauthorized'
          })
        } else {
          return Promise.reject(false)
        }
      }

      return Promise.resolve(request.data)
    },

    /**
     * Stores the answer value of a field
     *
     * @param {String} endpointURI
     * @param {Object} customBody
     * @return {Promise}
     */
    async storeAnswer(endpointURI, customBody = {}) {
      let body = Object.assign({
        //
      }, customBody)

      try {
        var request = await this.axios.post(endpointURI, body)
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'Public.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'Public.Unauthorized'
          })
        } else {
          if (error.response.data.errors !== undefined && typeof error.response.data.errors === 'object' && !Array.isArray(error.response.data.errors)) {
            return Promise.reject(error.response.data.errors[Object.keys(error.response.data.errors)[0]])
          } else if (error.response.data.errors !== undefined && error.response.data.errors.length) {
            return Promise.reject(error.response.data.errors)
          }
          return Promise.reject(error.response.data)
        }
      }

      return Promise.resolve(request.data)
    },

    /**
     * Updates an answer value of a field
     *
     * @param {String} endpointURI
     * @param {Object} customBody
     * @return {Promise}
     */
    async updateAnswer(endpointURI, customBody = {}) {
      let body = Object.assign({
        //
      }, customBody)

      try {
        var request = await this.axios.put(endpointURI, body)
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'Public.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'Public.Unauthorized'
          })
        } else {
          if (error.response.data.errors !== undefined && typeof error.response.data.errors === 'object' && !Array.isArray(error.response.data.errors)) {
            return Promise.reject(error.response.data.errors[Object.keys(error.response.data.errors)[0]])
          } else if (error.response.data.errors !== undefined && error.response.data.errors.length) {
            return Promise.reject(error.response.data.errors)
          }
          return Promise.reject(error.response.data)
        }
      }
      return Promise.resolve(request.data)
    },

    /**
     * Deletes an answer value of a field
     *
     * @param {String} endpointURI
     * @param {Object} customBody
     * @return {Promise}
     */
    async deleteAnswer(endpointURI, customBody = {}) {
      let body = Object.assign({
        //
      }, customBody)

      try {
        var request = await this.axios.delete(endpointURI, body)
      } catch (error) {
        if (error.response.status === 404) {
          return this.$router.push({
            name: 'Public.NotFound'
          })
        } else if (error.response.status === 403) {
          return this.$router.push({
            name: 'Public.Unauthorized'
          })
        } else {
          if (error.response.data.errors !== undefined && typeof error.response.data.errors === 'object' && !Array.isArray(error.response.data.errors)) {
            return Promise.reject(error.response.data.errors[Object.keys(error.response.data.errors)[0]])
          } else if (error.response.data.errors !== undefined && error.response.data.errors.length) {
            return Promise.reject(error.response.data.errors)
          }
          return Promise.reject(error.response.data)
        }
      }

      return Promise.resolve(request.data)
    },

    /**
     * Process event when form input changes. It also creates a form submission if not already exists.
     *
     * @param {Object} question
     * @param {Mixed} value
     */
    async inputChanged(question, value) {
      if (this.isFinalized) return
      if (question.touched_consequences && question.touched_consequences.length) {
        this.$emit('inputChanged', question.touched_consequences)
      }

      let store = true
      let remove = false
      this.lastSaved = moment()
      let submissionData = this.submissionData
      if (!this.hasSubmission) {
        submissionData = await this.storeSubmission(`v1/public/surveys/submissions`)
        if (submissionData !== false) {
          await this.$emit('update:submissionData', submissionData)
        }
      }

      if (question.type.name === 'upload') {
        if (value !== null) {
          value = {
            name: value.name,
            size: value.size,
            type: value.type,
            baseUri: `${process.env.VUE_APP_MS_BLOB_STORAGE_URL}/forms/submissions/${this.submissionData.id}`
          }
        } else {
          remove = true
          store = false
        }
      } else if (question.type.name === 'datetime') {
        value = moment(value).format('YYYY-MM-DD HH:mm')
      }

      if (store) {
        try {
          let storedAnswer = null
          if (question.answer && !!Object.keys(question.answer).length) {
            storedAnswer = await this.updateAnswer(`v1/public/surveys/answers/${question.answer.id}`, {
              value: value
            })
          } else {
            storedAnswer = await this.storeAnswer(`v1/public/surveys/answers`, {
              submission_id: submissionData.id,
              question_id: question.id,
              value: value
            })
          }
          question.answer = storedAnswer
        } catch (error) {
          question.errors = error
        }
      }

      if (remove) {
        try {
          await this.deleteAnswer(`v1/public/surveys/answers/${question.answer.id}`)
          question.answer = null
        } catch (error) {
          question.errors = error
        }
      }

      if(question.type.name !== 'textarea' && question.type.name !== 'text'){
        question.value = value
      }
      this.$emit('update:waitForSaving', false)
      return question
    },

    getFieldComponentByType(name) {
      let componentName = ''
      switch (name) {
        case 'number':
          componentName = 'InputNumber'
          break;
        case 'bool':
          componentName = 'InputBoolean'
          break;
        case 'checkbox':
          componentName = 'InputCheckbox'
          break;
        case 'radio':
          componentName = 'InputRadio'
          break;
        case 'datetime':
          componentName = 'InputDateTime'
          break;
        case 'date':
          componentName = 'InputDate'
          break;
        case 'time':
          componentName = 'InputTime'
          break;
        case 'email':
          componentName = 'InputEmail'
          break;
        case 'upload':
          componentName = 'InputFile'
          break;
        case 'textarea':
          componentName = 'InputTextarea'
          break;
        case 'select':
          componentName = 'InputSelect'
          break;
        case 'tel':
          componentName = 'InputTel'
          break;
        case 'url':
          componentName = 'InputUrl'
          break;
        case 'repeater':
          componentName = 'Repeater'
          break;
        case 'QR':
          componentName = 'QR'
          break;
        case 'location':
          componentName = 'Location'
          break;

        default:
          componentName = 'InputText'
          break;
      }
      return componentName
    },

    getRestrictionValueByTypeName(name, restrictions) {
      let relatedRestriction = restrictions.filter((restriction) => restriction.type.name === name)
      if (relatedRestriction.length) {
        return relatedRestriction[0][relatedRestriction[0].type.check_field]
      }
    },

    /**
     * Set's the meta of the current route and emits an event to rerender breadcrumb
     *
     * @param {String} title
     * @return {Promise}
     */
    async setRouteMeta(title, formTitle) {
      let formRoute = this.$route.matched.filter((route) => route.name === 'Public.Forms.Single')
      let sectionRoute = this.$route.matched.filter((route) => route.name === 'Public.Forms.Single.Section')
      this.$route.meta.title = formTitle
      if (formRoute.length) {
        formRoute[0].meta.breadcrumbTitle = formTitle
      }
      if (sectionRoute.length) {
        sectionRoute[0].meta.title = formTitle
        sectionRoute[0].meta.breadcrumbTitle = title
      }

      this.$store.dispatch('renderRouteMeta')
      return Promise.resolve()
    },

    previousSection() {
      if (this.waitForSaving) return
      this.$emit('previous')
    },

    nextSection() {
      if (this.waitForSaving) return
      this.$emit('next')
    },

    finalize() {
      if (this.waitForSaving) return
      this.$emit('finalize')
    },
  },

  beforeRouteUpdate() {
    setTimeout(() => this.setRouteMeta(this.sectionData.title, this.formTitle), 50);
  },

  beforeRouteLeave(to, from, next) {
    if (this.waitForSaving) next(false)
    next()
  }
}
</script>
