<template>
  <div class="space-y-5">
    <div v-if="submissionErrors.length" class="bg-red-50 rounded p-2 border border-red-200">
      <p class="text-red-700 font-medium">Inzending kan niet worden verstuurd, bekijk onderstaande vragen.</p>
      <div v-for="error in submissionErrors" :key="error" class="flex">
        <div class="mr-2">
          <i class="fas fa-circle-exclamation text-red-400" aria-hidden="true" />
        </div>
        <p class="text-red-700 truncate">Sectie {{error.sectie}} - Vraag {{error.vraagnr}} : {{error.vraag}}</p>
      </div>
    </div>
    <div class="bg-white shadow overflow-hidden rounded-lg" v-for="(section, sectionIndex) in visibleSectionsInForm(sections)" :key="sectionIndex">
      <div class="p-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900">{{ section.title }}</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">{{ section.description }}</p>
      </div>
      <div class="border-t border-gray-200">
        <dl>
          <div
            v-for="(question, questionIndex) in visibleQuestionsInSection(section)"
            :key="questionIndex"
            :class="[
              'p-4',
              { 'bg-gray-50': questionIndex % 2 === 0 }
            ]">
            <QuestionAnswers
              :sectionId="section.id"
              :formId="formId"
              :title="question.title"
              :required="!!question.required"
              :id="question.id"
              :type="question.type"
              v-bind:value="question.value"
              :restrictions="question.restrictions"
              :errors="question.errors"
              :submissionData="submissionData"
              :options="question.options"
            />
          </div>
        </dl>
      </div>
    </div>

    <div class="bg-white shadow overflow-hidden lg:rounded-lg p-4" v-show="!sections.length">
      Er zijn geen secties beschikbaar...
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import QuestionAnswers from '@/components/forms/finalize/QuestionAnswers'

export default {
  name: 'Public.Forms.Single.Finalize',

  inject: ['axios'],

  emits: [
    'update:routes',
    'update:submissionData',
    'update:sectionData',
    'previous',
    'next',
    'submit',
  ],

  components: {
    QuestionAnswers
  },

  props: {
    formId: Number,
    formTitle: String,
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    sectionData: {
      type: Object,
      default() {
        return {}
      }
    },
    submissionData: {
      type: Object,
      default() {
        return {}
      }
    },
    hasPreviousBtn: {
      type: Boolean,
      default: false
    },
    hasNextBtn: {
      type: Boolean,
      default: false
    },
    hasFinishBtn: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      accountStorageURI: process.env.VUE_APP_MS_BLOB_STORAGE_URL,
      submissionErrors: []
    }
  },

  watch: {
    formData: {
      deep: true,
      handler() {
        this.checkForErros()
      }
    }
  },

  computed: {
    sections() {
      return this.formData.sections.filter((section) => {
        return section.questions.filter((question) => !!question.visible).length
      })
    },

    hasSubmission() {
      return !!Object.keys(this.submissionData).length
    },

    submissionId() {
      return this.submissionData.id
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.setRouteMeta(this.sectionData.title, this.formTitle)
      this.checkForErros()
    },

    getRestrictionValueByTypeName(name, restrictions) {
      let relatedRestriction = restrictions.filter((restriction) => restriction.type.name === name)
      if (relatedRestriction.length) {
        return relatedRestriction[0][relatedRestriction[0].type.check_field]
      }
    },

    visibleQuestionsInSection(section) {
      return section.questions.filter((question) => !!question.visible)
    },

    visibleSectionsInForm(sections) {
      return sections.filter((section) => section.visible == true)
    },

    checkForErros(){
      let sections = this.sections
      this.submissionErrors = []
      for(let i = 0; i < sections.length; i++){
        if(sections[i].questions.length){
          for(let j = 0; j < sections[i].questions.length; j++){
            if(sections[i].questions[j].errors){
              if(sections[i].questions[j].errors.length){
                this.submissionErrors.push({vraag: sections[i].questions[j].title, sectie: i +1, vraagnr: j+1})
              }
            }
          }
        }
      }
    },

    parseValueByQuestionType(question) {
      if (question.value === undefined) {
        return question.value
      }

      let parsedValue = question.value
      if (['bool', 'select', 'radio'].includes(question.type.name)) {
        const relevantOption = question.options.filter((option) => option.option_value == question.value)
        if (relevantOption.length) {
          parsedValue = relevantOption[0].option_name
        }
      }
      if (question.type.name === 'checkbox') {
        let combinedNewValue = []
        const explodedValue = question.value.split(',')
        for (let i = 0; i < explodedValue.length; i++) {
          const value = explodedValue[i]
          const relevantOption = question.options.filter((option) => option.option_value == value)
          if (relevantOption.length) {
            combinedNewValue.push(relevantOption[0].option_name)
          }
        }
        parsedValue = combinedNewValue
      }

      return parsedValue
    },

    /**
     * Set's the meta of the current route and emits an event to rerender breadcrumb
     *
     * @param {String} title
     * @return {Promise}
     */
    async setRouteMeta(title, formTitle) {
      let formRoute = this.$route.matched.filter((route) => route.name === 'Public.Forms.Single')
      let sectionRoute = this.$route.matched.filter((route) => route.name === 'Public.Forms.Single.Section')
      if (sectionRoute.length) {
        sectionRoute[0].meta.breadcrumbTitle = title
      }
      if (formRoute.length) {
        formRoute[0].meta.breadcrumbTitle = formTitle
      }
      return Promise.resolve()
    },

    previousSection() {
      this.answersFilled = false
      this.$emit('previous')
    },

    nextSection() {
      this.answersFilled = false
      this.$emit('next')
    },

    submitSection() {
      this.$emit('submit')
    },

    moment(constructor = null) {
      return constructor !== null ? moment(constructor) : moment
    },
  },

  beforeRouteUpdate() {
    this.setRouteMeta(this.sectionData.title, this.formTitle)
  }
}
</script>
